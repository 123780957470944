<template>
    <div class="requests-show-client-details page-inner-sidebar col-3" style="min-width: 100%; padding: 0; margin: 0;">
        <!-- Client Details -->
        <div class="acms-v-card block block-rounded" style="margin-bottom: 16px; width: 100%; box-shadow: none;">
            <div class="acms-v-card-inner" style="padding: 0;">
                <div class="block-header block-header-default justify-content-between" style="padding: 12px 16px; border-bottom: 1px solid #e5e7eb;">
                    <h3 class="block-title text-gray-darker" style="font-size: 16px; font-weight: 600;">
                        <svg height="16" width="16" aria-hidden="true" style="margin-right: 8px;">
                            <use href="#icon-user"></use>
                        </svg>
                        Client
                    </h3>
                    <div class="d-flex align-items-center">
                        <div class="acms-v-badge badge btn-alt-primary" style="font-size: 12px; margin-right: 8px;">New prospect</div>
                        <button class="acms-v-btn btn d-flex align-items-center bg-gray-light" style="width: 32px; height: 32px;" @click="openAltAccordion('contact-info-custom')">
                            <div class="acms-v-icon d-flex acms-v-icon-pen acms-v-btn-icon">
                                <svg height="16" width="16" aria-hidden="true">
                                    <use fill="" href="#icon-pen"></use>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="block-content" style="padding: 16px;">

                    <div class="client-details-info">
                        <div class="client-details-info-row mb-3">
                            <div>
                                <div class="client-overview-info-content text-gray-darker fz-20 fw-bold text-center" style="border-bottom: 2px solid rgb(157 160 162);padding-bottom: 4px;">
                                    <a :href="`/clients/edit/${client.id}`" style="color: #343A40;    text-decoration: underline; text-decoration-color: #2299dd; text-underline-offset: 3px;text-decoration-thickness: 2px;text-decoration-skip-ink: none; "> {{ getFullName(client) }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- Email Addresses -->
                        <div class="client-details-info-emails" v-if="displayedEmails.length > 0">
                            <h4 style="font-size: 14px; font-weight: bold; color: #333; margin-bottom: 8px;">Email Addresses</h4>
                            <div class="d-flex flex-column">
                                <div
                                    v-for="(email, index) in displayedEmails"
                                    :key="'email-'+ index"
                                    class="d-flex justify-content-between align-items-center mb-2"
                                >
                                    <div class="d-flex align-items-center">
                                        <div
                                            class="badge text-uppercase text-gray-darker me-2"
                                            :style="email.title.split(' ')[0] === 'P' ? activeBadgeStyle : inactiveBadgeStyle"
                                            style="font-size: 12px; padding: 2px 4px; border-radius: 3px; margin-right: 8px;"
                                        >
                                            {{ email.title.split(' ')[0] }}
                                        </div>
                                        <div class="client-overview-info-content text-gray-darker fz-14 fw-normal">
                                            <template v-if="email.content.obscure">
                                                {{email.content.obscureValue}}
                                            </template>
                                            <template v-else>
                                                {{ email.content.originalValue }}
                                            </template>
                                        </div>
                                    </div>
                                    <div style="display:flex;">
                                        <v-btn style="margin-right: 5px!important;" v-if="!email.content.obscure" elevation="0" class="eye1-btn" size="25" :href="'https://thatsthem.com/email/'+email.content.originalValue" target="_blank">
                                            <v-icon>
                                                mdi-account-search-outline
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            elevation="0"
                                            class="eye1-btn"
                                            style="margin-right: 5px!important"
                                            :disabled="email.content?.loading || !email.canToggleObscure"
                                            :icon="email.content.obscure ? 'mdi-eye-off' : 'mdi-eye'"
                                            @click="obscureContactHandle(email)"
                                            width="25"
                                            height="25"
                                        >
                                            <v-tooltip  activator="parent" location="right">
                                                {{getTooltipRevealsLeft}}
                                            </v-tooltip>
                                            <v-icon :size="19">
                                                {{ email.content.obscure ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
                                            </v-icon>
                                        </v-btn>
                                    </div>


                                </div>
                            </div>
                            <!-- View More Button -->
                            <div v-if="getMails.length > 2" style="text-align: center;">
                                <button
                                    class="btn btn-secondary"
                                    id="view-more-btn"
                                    style="font-size: 12px; font-weight: 600; color: #333; background-color: #f0f0f0; border-radius: 4px; padding: 4px 8px; border: 1px solid #ccc; margin-top: 8px;"
                                    @click="toggleShowAllEmails"
                                >
                                    {{ showAllEmails ? 'View less' : `View ${getMails.length - 2} more` }}
                                </button>
                            </div>
                        </div>
                        <!-- Phone Numbers -->
                        <div class="client-details-info-phones" v-if="displayedPhones.length > 0">
                            <h4 style="font-size: 14px; font-weight: bold; color: #333; margin-top: 16px; margin-bottom: 8px;">Phone Numbers</h4>
                            <div class="d-flex flex-column">
                                <div
                                    v-for="(phone, index) in displayedPhones"
                                    :key="'phone-'+index"
                                    class="d-flex justify-content-between align-items-center mb-2"
                                >
                                    <div class="d-flex align-items-center">
                                        <div
                                            class="badge text-uppercase text-gray-darker me-2"
                                            :style="phone.title.split(' ')[0] === 'P' ? activeBadgeStyle : inactiveBadgeStyle"
                                            style="font-size: 12px; padding: 2px 4px; border-radius: 3px; margin-right: 8px;"
                                        >
                                            {{ phone.title.split(' ')[0] }}
                                        </div>
                                        <div class="client-overview-info-content text-gray-darker fz-14 fw-normal">
                                            <template v-if="phone.content.obscure">
                                                {{phone.content.obscureValue}}
                                            </template>
                                            <template v-else-if="phone.contact_type === 'phone'">
                                                <a :href="'tel:'+phone.content.originalValue"> {{ phone.content.originalValue }}</a>
                                            </template>
                                            <template v-else>
                                                {{ phone.content.originalValue }}
                                            </template>
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <CallButton
                                            :phone="phone"
                                            @start-call="startCallHandle"
                                        />
                                        <v-btn  style="margin-right: 5px!important;"  v-if="!phone.content.obscure" elevation="0" class="eye1-btn" size="25" :href="'https://thatsthem.com/searching?phone='+phone.content.originalValue" target="_blank">
                                            <v-icon>
                                                mdi-account-search-outline
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            class="eye1-btn"
                                            elevation="0"
                                            style="margin-right: 5px!important"
                                            :disabled="phone.content?.loading || !phone.canToggleObscure"
                                            :icon="phone.content.obscure ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                            @click="obscureContactHandle(phone)"
                                            width="25"
                                            height="25"
                                        >
                                            <v-tooltip  activator="parent" location="right">
                                                {{getTooltipRevealsLeft}}
                                            </v-tooltip>
                                            <v-icon :size="19">
                                                {{ phone.content.obscure ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <!-- View More Button -->
                            <div v-if="getPhones.length > 2" style="text-align: center;">
                                <button
                                    class="btn btn-secondary"
                                    id="view-more-btn"
                                    style="font-size: 12px; font-weight: 600; color: #333; background-color: #f0f0f0; border-radius: 4px; padding: 4px 8px; border: 1px solid #ccc; margin-top: 8px;"
                                    @click="toggleShowAll"
                                >
                                    {{ showAllPhones ? 'View less' : `View ${getPhones.length - 2} more` }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="acms-v-card block block-rounded" style="margin-bottom: 16px; width: 100%; box-shadow: none;">
            <div class="acms-v-card-inner" style="padding: 0;">
                <div class="block-header block-header-default" style="padding: 12px 16px; border-bottom: 1px solid #e5e7eb;">
                    <h3 class="block-title text-gray-darker" style="font-size: 16px; font-weight: 600;">
                        <v-icon icon="mdi-calendar-check" size="20"></v-icon>
                        Follow-up widgets
                    </h3>
                </div>
                <!-- Follow-up Widgets -->
                <div class="block-content" style="padding: 8px;">
                    <div class="d-flex align-items-center">
                        <PartialRequestClientDetailsActions
                            style="margin-bottom: 10px"
                            :emailCount="2"
                            :phoneCount="2"
                            :pqCount="2"
                            @followUpClick="detailsActions.followUp.show = true"
                            class="mt-4"
                            :followUpValue="detailsActions.followUp.date"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Assigned Agents -->
        <div class="acms-v-card block block-rounded" style="margin-bottom: 16px; width: 100%; box-shadow: none;">
            <div class="acms-v-card-inner" style="padding: 0;">
                <div class="block-header block-header-default" style="padding: 12px 16px; border-bottom: 1px solid #e5e7eb;">
                    <h3 class="block-title text-gray-darker" style="font-size: 16px; font-weight: 600;">
                        <v-icon icon="mdi-face-agent" size="20"></v-icon>
                        Agents
                    </h3>
                </div>
                <div class="block-content" style="padding: 16px;">
                    <div class="d-flex align-items-center">
                        <div
                            v-for="(ag, index) in agent"
                            :key="index"
                            class="badge bg-gray-light text-gray-darker d-flex align-items-center"
                            style="padding: 4px 8px; margin-right: 8px; border-radius: 4px;"
                        >
                            <div class="acms-v-icon d-flex acms-v-icon-user me-2">
                            </div>
                            <span>{{ getFullName(ag) }}</span>
                            <button
                                v-if="backendPermissions.allowUnAssignAgents"
                                class="ms-2 text-danger"
                                style="border: none; background: none; font-size: 10px; cursor: pointer;"
                                @click="confirmRemoveAgent(ag)"
                            >
                                &#10060;
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Flight Details -->
        <div class="acms-v-card block block-rounded" style="margin-bottom: 16px; width: 100%; box-shadow: none;">
            <div class="acms-v-card-inner" style="padding: 0;">
                <div class="block-header block-header-default justify-content-between" style="padding: 12px 16px; border-bottom: 1px solid #e5e7eb;">
                    <h3 class="block-title text-gray-darker" style="font-size: 16px; font-weight: 600;">
                        <svg height="16" width="16" aria-hidden="true" style="margin-right: 8px;">
                            <use href="#icon-plane"></use>
                        </svg>
                        Flight Details
                    </h3>
                    <v-badge floating offset-x="50" max="100" offset-y="3" v-if="previousSearches.length > 1" rounded="1px" :content="previousSearches.length">
                        <v-btn

                            variant="outlined"
                            class="widget"
                            @click="fetchPreviousSearches(false)"
                        >
                            <v-icon color="#495057" size="16">
                                mdi-airplane-search
                            </v-icon>
                        </v-btn>
                    </v-badge>

                    <v-dialog v-model="dialogSearches" width="auto">
                        <v-card max-width="1200">
                            <v-card-title>Previous Searches</v-card-title>

                            <v-card-text>
                                <!-- Состояние загрузки -->
                                <div v-if="loading" style="text-align: center; padding: 16px;">
                                    Loading...
                                </div>
                                <!-- Ошибка -->
                                <div v-if="error" style="text-align: center; color: red; padding: 16px;">
                                    {{ error }}
                                </div>

                                <!-- Таблица -->
                                <table
                                    v-if="!loading && !error && previousSearches.length > 0"
                                    style="width:100%; border-collapse:collapse; font-size:14px;"
                                >
                                    <thead>
                                    <tr>
                                        <th
                                            v-for="header in tableHeaders"
                                            :key="header"
                                            style="border:1px solid #cccccc; background-color:#eeeeee; padding:6px 8px; font-weight:normal; text-align:left;"
                                        >
                                            {{ header }}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(search, searchIndex) in previousSearches">
                                        <tr
                                            style="border:1px solid;"
                                            v-for="(segment, segmentIndex) in search.segments"
                                            :key="`search-${searchIndex}-segment-${segmentIndex}`"
                                        >
                                            <td :style="getHighlightStyle(searchIndex, segmentIndex, 'created_at') + ';text-align: center; padding:4px 8px;'">
                                                {{ formatDate(search.created_at) }}
                                            </td>
                                            <!-- Trip Type -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'trip_type')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ search.trip_type || '' }}
                                            </td>
                                            <!-- From City -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'from_city.iata')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ segment.from_city?.iata || '' }}
                                            </td>
                                            <!-- To City -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'to_city.iata')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ segment.to_city?.iata || '' }}
                                            </td>
                                            <!-- Departure Date -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'departure_date')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ formatDate(segment.departure_date) }}
                                            </td>
                                            <!-- Arrival Date -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'arrival_date')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ formatDate(segment.arrival_date) }}
                                            </td>
                                            <!-- Cabin Type -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'cabin_type')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ search.cabin_type || '' }}
                                            </td>
                                            <!-- Adults -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'adult_count')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ search.adult_count || '0' }}
                                            </td>
                                            <!-- Children -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'children_count')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ search.children_count || '0' }}
                                            </td>
                                            <!-- Infants -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'infant_count')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                {{ search.infant_count || '0' }}
                                            </td>
                                            <!-- Price -->
                                            <td
                                                :class="getCellHighlightClass(searchIndex, segmentIndex, 'price')"
                                                style="border:1px solid #cccccc; padding:6px 8px;"
                                            >
                                                ${{ search.price || '0' }}
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>

                                <!-- Если нет данных -->
                                <div v-if="!loading && !error && previousSearches.length === 0" style="text-align: center; padding: 16px;">
                                    No previous searches found.
                                </div>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn class="ms-auto" text="Ok" @click="dialogSearches = false"></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <button class="acms-v-btn btn d-flex align-items-center bg-gray-light" style="width: 32px; height: 32px;" @click="openAccordion(currentRequest.id)">
                        <div class="acms-v-icon d-flex acms-v-icon-pen acms-v-btn-icon">
                            <svg height="16" width="16" aria-hidden="true">
                                <use fill="" href="#icon-pen"></use>
                            </svg>
                        </div>
                    </button>
                </div>
                <div class="block-content" style="padding: 16px;">
                    <div class="client-details-flights-list">
                        <div
                            v-for="(item, index) in currentRequest.flight_legs"
                            :key="index"
                            class="d-flex text-gray-darker fz-14 fw-normal align-items-center"
                            style="margin-bottom: 6px; line-height: 1.4; justify-content: space-between;"
                        >
                            <div style="display: flex;">
                                <div style="display: flex; margin-right: 4px;">
                                    <div
                                        v-for="(cabin_class_item, idx) in item.cabin_classes"
                                        :key="idx"
                                        :style="getIconStyle(cabinColor(cabin_class_item.id))"
                                    >
                                        <span v-tooltip="'CABIN TYPE : ' + cabin_class_item?.title">{{ cabin(cabin_class_item.id) }}</span>
                                    </div>
                                </div>
                                <div class="me-1" v-tooltip="item.departure_airport_object?.title ?? item.departure_airport_id">{{ item.departure_airport_id }}</div>
                                <div class="me-1">&rarr;</div>
                                <div class="d-flex">
                                    <div class="me-1" v-tooltip="item.arrival_airport_object?.title ?? item.arrival_airport_id">{{ item.arrival_airport_id }}</div>
                                </div>
                            </div>
                            <span class="text-uppercase" style="font-size: 14px;">{{ getFlightDates(item).dates }}</span>
                        </div>
                        <v-divider></v-divider>
                        <EntityCellPassengers class="fz-16" :data="getPassengersCount"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Preferences -->
        <div class="acms-v-card block block-rounded" style="width: 100%; box-shadow: none;">
            <div class="acms-v-card-inner" style="padding: 0;">
                <div class="block-header block-header-default justify-content-between" style="padding: 12px 16px; border-bottom: 1px solid #e5e7eb;">
                    <h3 class="block-title text-gray-darker" style="font-size: 16px; font-weight: 600;">
                        <v-icon icon="mdi-star-settings-outline" size="20"></v-icon>
                        Preferences
                    </h3>
                    <button class="acms-v-btn btn d-flex align-items-center bg-gray-light" style="width: 32px; height: 32px;" @click="openAccordion(currentRequest.id)">
                        <div class="acms-v-icon d-flex acms-v-icon-pen acms-v-btn-icon">
                            <svg height="16" width="16" aria-hidden="true">
                                <use fill="" href="#icon-pen"></use>
                            </svg>
                        </div>
                    </button>
                </div>
                <div class="block-content" style="padding: 16px;">
                    <div class="table-mini">
                        <div
                            v-for="(item, index) in preferencesTableData"
                            :key="index"
                            :class="'table-mini-row'"
                            class="d-flex align-items-center justify-content-between ph-2"
                            style="margin-bottom: 8px; padding: 8px; border-bottom: 1px solid #e5e7eb;"
                        >
                            <div class="table-mini-cell text-gray-60 text-uppercase" style="font-weight: 600; font-size: 14px; color: #333;">
                                {{ item.title }}
                            </div>
                            <div v-if="Array.isArray(item.content)" class="d-flex flex-wrap">
                                <template v-for="(itm, key) in item.content" :key="key">
                                    <div v-if="key > 0">, </div>
                                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-weight: 500; font-size: 14px; color: #555;">
                                        <span v-tooltip="itm.tooltipContent">{{ itm.title }}</span>
                                    </div>
                                </template>
                            </div>
                            <div v-else v-tooltip="item.content" class="table-mini-cell" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-weight: 500; font-size: 14px; color: #555;">
                                {{ item.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <PartialFollowUpCalendar v-model="detailsActions.followUp.date" :show="detailsActions.followUp.show" @hide="hideFollowUpCalendarHandle"/>

    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title class="headline">Unassignment</v-card-title>
            <v-card-text>Would you like to unassigned agent "{{ selectedAgentName }}" from this request?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                <v-btn color="red darken-1" text @click="removeAgent">Unassign</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import {ref, computed, onBeforeMount, reactive, watch, watchEffect, onMounted} from 'vue';
import axios from 'axios';
import { PartialRequestClientDetailsActions, PartialFollowUpCalendar } from './partials';
import { usePageRequestShowStore } from '@store/pages/page-request-edit';
import { EntityCellPassengers } from '@entities';
import { getFullName, getHiddenEmail, getHiddenPhone, getSecurizedPhone } from "@helpers/commonHelpers";
import { useBackendMiddlewarePropsService } from "@services";
import { getUppercaseDates } from "@helpers/dateHelpers";
import httpClient from "@services/HttpService";
import { getRoute } from "@plugins/useRoutes";
import { useAlertsManagerStore } from "@store/plugins/alerts-manager";
import { usePage } from "@inertiajs/vue3";
import CallButton from "@boxes/request/BoxCardRequestClientDetails/CallAnimation.vue";

const props = defineProps({
    client: Object,
    currentRequest: Object,
    agent: Array
});
console.log('props.client', props.client);
const requestShowStore = usePageRequestShowStore();
const { backendOptions, backendPermissions } = useBackendMiddlewarePropsService();
const clientAbout = ref({});
const requestClientDetails = ref({ info: {} });
const preferencesTableData = ref([]);
const detailsActions = reactive({
    followUp: {
        show: false,
        date: null
    },
});
const currentRevealsLeft = ref(0);
const dialog = ref(false);
const dialogSearches = ref(false);
const selectedAgent = ref(null);
const selectedAgentName = ref(null);
const previousSearches = ref([]); // Массив для данных из API
const loading = ref(false); // Индикация загрузки
const error = ref(null); // Для обработки ошибок
const tableHeaders = [
    'Searched on',
    'Trip',
    'From',
    'To',
    'Departure',
    'Return',
    'Cabin',
    'Adults',
    'Children',
    'Infants',
    'Price',
];

// Метод для загрузки данных
const fetchPreviousSearches = async (isStart = false) => {
    loading.value = true; // Показываем индикатор загрузки
    error.value = null; // Сбрасываем ошибку
    console.log('is', isStart)
    if (!isStart) {
        dialogSearches.value = true; // Открываем диалог при запросе
    }


    try {
        // POST-запрос к API
        const response = await axios.post('https://back.cybercraft.software/api/previous-searches', {
            visitor_id: props.client.fp_visitor_id, // Используем visitor_id из пропсов
        });

        // Обрабатываем данные ответа
        previousSearches.value = response.data.previous_searches || [];
    } catch (err) {
        console.error('Error fetching previous searches:', err);
        error.value = 'Failed to load previous searches.';
    } finally {
        loading.value = false; // Завершаем загрузку
    }
};

// Метод для форматирования даты
const formatDate = (date) => {
    if (!date) return '';
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    return new Date(date).toLocaleDateString('en-GB', options);
};

// Метод для подсветки строк
const getHighlightStyle = (searchIndex, segmentIndex, field) => {
    const currentSearch = previousSearches.value[searchIndex];
    const nextSearch = previousSearches.value[searchIndex + 1] || null;

    if (!currentSearch || !nextSearch) return '';

    const currentDate = new Date(currentSearch[field]);
    const nextDate = nextSearch[field] ? new Date(nextSearch[field]) : null;

    if (field === 'created_at' && nextDate && Math.abs(currentDate - nextDate) / 36e5 > 1) {
        return 'background-color:#ffd77f;';
    }
    return '';
};

// Логика подсветки
const getCellHighlightClass = (searchIndex, segmentIndex, field) => {
    const currentSearch = previousSearches.value[searchIndex];
    const nextSearch = previousSearches.value[searchIndex + 1] || null;

    // Если следующего поиска нет, подсветка невозможна
    if (!nextSearch) return '';

    // Проверяем сегменты (если поле внутри `segments`)
    const currentSegment = currentSearch.segments[segmentIndex];
    const nextSegment = nextSearch.segments[segmentIndex];

    // Подсветка "trip_type"
    if (field === 'trip_type' && currentSearch[field] !== nextSearch[field]) {
        return 'highlight';
    }

    // Подсветка других полей в сегментах
    if (field.includes('.') && currentSegment && nextSegment) {
        const [parentField, childField] = field.split('.');
        if (currentSegment[parentField]?.[childField] !== nextSegment[parentField]?.[childField]) {
            return 'highlight';
        }
    }

    // Подсветка числовых или строковых полей
    if (currentSearch[field] !== nextSearch[field]) {
        return 'highlight';
    }

    return '';
};
const getTooltipRevealsLeft = computed(() => String(currentRevealsLeft.value) + ' reveals left');

const displayedEmails = ref([]);
const displayedPhones = ref([]);

const page = usePage();
const startCallHandle = (item) => {
    // console.log('user', props)
    if (!item || !item.contact_id) {
        console.error("Item or ID is missing!");
        return;
    }

    const { contact_id } = item;

    axios.post('/api/call/start', { id: contact_id , agent_phone : page.props.auth_user.work_data.work_phone ?? null, dialpad_id: page.props.auth_user.dialpad_id ?? null})
        .then(response => {
            console.log("Call started successfully:", response.data);
        })
        .catch(error => {
            console.error("Error starting call:", error);
        });
}

const obscureContactHandle = (item) => {
    const { contact_id, contact_type } = item;
    const client_id = props.client.id;
    const sendObject = { contact_id, contact_type, client_id };
    if (!item.content.originalValue) {
        item.content.loading = true;
        httpClient.post(getRoute('clients.contact.get'), sendObject).then(({ data }) => {
            if (data?.success) {
                const updatedItem = {
                    ...item,
                    content: {
                        ...item.content,
                        loading: false,
                        originalValue: data?.data,
                        obscure: false
                    }
                };

                if (contact_type === 'phone') {
                    const index = displayedPhones.value.findIndex(phone => phone.contact_id === contact_id);
                    if (index !== -1) {
                        displayedPhones.value[index] = updatedItem;
                    }
                } else if (contact_type === 'email') {
                    const index = displayedEmails.value.findIndex(email => email.contact_id === contact_id);
                    if (index !== -1) {
                        displayedEmails.value[index] = updatedItem;
                    }
                }

                if (data?.count_remain) {
                    currentRevealsLeft.value = data?.count_remain;
                }
            } else if (data?.error) {
                // Handle error
            }
        });
    } else {
        item.content.obscure = !item.content.obscure;
        if (item.contact_type === 'phone') {
            const index = displayedPhones.value.findIndex(phone => phone.contact_id === item.contact_id);
            if (index !== -1) {
                displayedPhones.value[index] = { ...item };
            }
        } else if (item.contact_type === 'email') {
            const index = displayedEmails.value.findIndex(email => email.contact_id === item.contact_id);
            if (index !== -1) {
                displayedEmails.value[index] = { ...item };
            }
        }
    }
};

watch(() => props.currentRequest, () => {
    setSidebarData();
});

const getInfo = computed(() => {
    return {};
});
onMounted(()=> {
    fetchPreviousSearches(true);
});
const cabin = (cabinClass) => {
    switch (cabinClass) {
        case 1:
            return 'B';
        case 2:
            return 'E';
        case 3:
            return 'F';
        case 4:
            return 'P';
        default:
            return 'B';
    }
}

const cabinColor = cabin => {
    switch (cabin) {
        case 2:
            return "#D97706";
        case 1:
            return "#556B2F";
        case 4:
            return "#2563EB";
        case 3:
            return "#d92626";
        default:
            return "#556B2F";
    }
}
const getPassengersCount = computed(() => {
    const { adults, children, infants } = props.currentRequest;
    return { adults, children, infants };
});

const emit = defineEmits(['open-accordion', 'open-alt-accordion']);
const openAccordion = (index) => {
    emit('open-accordion', index);
};
const openAltAccordion = (type) => {
    emit('open-alt-accordion', type);
};
const getFlightDates = (item) => {
    const findCabin = backendOptions.cabinClassOptions.find(i => i.id == item.cabin_class_id);
    const cabinClass = () => {
        let short = 'B';
        let full = 'Business';
        if (findCabin) {
            short = findCabin.title?.substring(0, 1);
            full = findCabin.title;
        }
        return { short, full };
    };
    return {
        dates: getUppercaseDates([item.flight_date_from, item.flight_date_to]),
        cabinClass: cabinClass()
    };
};

const getMails = computed(() => {
    if (props.client?.email?.length > 0) {
        return props.client?.email?.reduce((acc, item) => {
            const sample = {
                title: ((item?.pivot?.type_id ?? item.id) === 1 ? 'P' : 'S') + ' email',
                full: true,
                order: 2,
                canToggleObscure: true,
                contact_id: item.id,
                contact_type: 'email',
                content: {
                    obscure: true,
                    obscureValue: item?.email,
                    originalValue: null,
                    text: item?.email ?? item.text,
                    loading: false
                },
            };
            const isMain = item.pivot?.main ?? item.main;
            if (isMain) {
                sample.order = 1;
                sample.title = sample.title + ' (Main)';
            }
            if (sample.content.text?.length > 0) {
                acc.push(sample);
            }
            return acc;
        }, []);
    }
    else {
        return []
    }
});

const getPhones = computed(() => {
    return props.client?.phone?.reduce((acc, item) => {
        const sample = {
            title: ((item?.pivot?.type_id ?? item.id) == 1 ? 'P' : 'S') + ' phone',
            order: 2,
            full: true,
            contact_id: item.id,
            contact_type: 'phone',
            canToggleObscure: getSecurizedPhone(item)?.includes('*'),
            content: {
                obscure: true,
                obscureValue: (item.dial_code ?? item.dial_code_object?.code) + ' ' + item?.phone,
                originalValue: null,
                text: (item.dial_code ?? item.dial_code_object?.code) + ' ' + item?.phone,
                loading: false
            },
        };
        const isMain = item.pivot?.main ?? item.main;
        if (isMain) {
            sample.title = sample.title + ' (Main)';
            sample.order = 1;
        }
        if (sample.content.text?.length > 0) {
            acc.push(sample);
        }
        return acc;
    }, []);
});

const setClientAbout = () => {
    const mails = getMails.value;
    const phones = getPhones.value;
    clientAbout.value = {};
    if (mails?.length > 0) {
        clientAbout.value.mails = mails;
    }
    if (phones?.length > 0) {
        clientAbout.value.phones = phones;
    }
};

const setClientInfo = () => {
    const { best_time_to_call } = props.client;
    const info = [];
    const contactBy = {
        title: 'Contact by',
        content: props.client.contact_by_id ? backendOptions.contactSocialOptions.find(item => item.id == props.client.contact_by_id).title : null,
    };

    if (contactBy.content) {
        info.push(contactBy);
    }
    if (best_time_to_call.from) {
        const from = `${best_time_to_call.from.time} ${best_time_to_call.from.type}`;
        const to = `${best_time_to_call.to.time} ${best_time_to_call.to.type}`;
        const bestTime = {
            title: 'Best time to call',
            content: `${from} - ${to}`,
            full: true,
        };
        info.push(bestTime);
    }

    return { info };
};

const setPreferencesTableInfo = () => {
    const {
        number_of_stops_id,
        purpose_of_travel_id,
        max_client_budget,
        values,
        client_need_a_hotel,
        seats_array,
        notes,
        airlines
    } = props.currentRequest;

    const list = [];
    const airlinesObj = {
        title: 'Airlines',
        content: airlines?.map(item => {
            const { iata, title } = item.airline;
            return { title: iata ?? 'No iata', tooltipContent: title };
        })
    };

    if (airlinesObj.content.length > 0) {
        list.push(airlinesObj);
    }

    const stops = {
        title: 'Stops',
        content: backendOptions.numberOfStopsOptions?.find(i => i.id == number_of_stops_id)?.title ?? null
    };
    const purpose = {
        title: 'Purpose',
        content: backendOptions.purposeOfTravelsOptions?.find(i => i.id == purpose_of_travel_id)?.title ?? null
    };
    const budget = {
        title: 'Budget',
        content: `$${max_client_budget}`
    };
    const valuesObj = {
        title: 'Values',
        content: values.map(item => {
            const find = backendOptions.clientMainValuesOptions?.find(i => i.id == item);
            return find?.title ?? item;
        }).join(', ')
    };
    const hotelObj = {
        title: 'HOTEL',
        content: client_need_a_hotel > 0 ? 'Need' : 'No need'
    };
    const seatsObj = {
        title: 'Seats',
        content: seats_array?.map(item => item.title).join(', ')
    };
    const notesObj = {
        title: 'Req. Notes',
        content: notes
    };
    [stops, purpose].forEach(item => {
        if (item.content) {
            list.push(item);
        }
    });
    if (max_client_budget) {
        list.push(budget);
    }

    if (values?.length > 0) {
        list.push(valuesObj);
    }

    if (client_need_a_hotel != null) {
        list.push(hotelObj);
    }

    if (seatsObj.content.length > 0) {
        list.push(seatsObj);
    }

    if (notesObj.content) {
        list.push(notesObj);
    }

    return list;
};

const setSidebarData = () => {
    requestClientDetails.value = setClientInfo();
    preferencesTableData.value = setPreferencesTableInfo();
};

const hideFollowUpCalendarHandle = () => {
    detailsActions.followUp.show = false;
    requestShowStore.toggleShowFollowUp(false);
};

watch(() => requestShowStore.followUpCalendarShow, (bool) => {
    if (bool) {
        detailsActions.followUp.show = true;
    }
});

onBeforeMount(() => {
    setSidebarData();
    setClientAbout();
});
watchEffect(() => {
    setClientAbout();
});

const confirmRemoveAgent = (agent) => {
    selectedAgent.value = agent;
    selectedAgentName.value = getFullName(agent);
    dialog.value = true;
};
const AlertsManager = useAlertsManagerStore();
const removeAgent = () => {
    if (selectedAgent.value) {
        axios.delete(`/requests/${props.currentRequest.id}/agents/${selectedAgent.value.id}`)
            .then(response => {
                console.log('response', response.data);
                if (response.data.success) {
                    if (Array.isArray(props.agent)) {
                        const index = props.agent.findIndex(a => a.id === selectedAgent.value.id);
                        console.log('Agent index:', index);
                        if (index !== -1) {
                            props.agent.splice(index, 1);
                            console.log('Updated agents:', props.agent);
                        } else {
                            console.error('Agent not found in currentRequest.agents');
                        }
                    } else {
                        console.error('agents array is not defined or not an array in currentRequest');
                    }
                    AlertsManager.add('Agent was successfully removed from request', 'success');
                } else {
                    alert(response.data.message);
                }
                dialog.value = false;
                selectedAgent.value = null;
                selectedAgentName.value = null;
            })
            .catch(error => {
                console.error('Failed to remove agent from request:', error);
                dialog.value = false;
                selectedAgent.value = null;
                selectedAgentName.value = null;
                AlertsManager.add('Cannot delete agent from request', 'danger');
            });
    }
};

const activeBadgeStyle = 'border: 1px solid #ffa500; color: #ffa500;';
const inactiveBadgeStyle = 'border: 1px solid #ccc; color: #ccc;';

const getIconStyle = (color) => {
    return {
        width: '18px',
        height: '18px',
        border: `1px solid ${color}`,
        color: color,
        borderRadius: '3px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        fontSize: '12px',
        marginRight: '4px',
    };
};
const showAllEmails = ref(false);

watchEffect(() => {
    displayedEmails.value = showAllEmails.value ? getMails.value : (getMails.value.length > 0 ? getMails.value.slice(0, 2) : []);
});

const toggleShowAllEmails = () => {
    showAllEmails.value = !showAllEmails.value;
};

const showAllPhones = ref(false);

watchEffect(() => {
    displayedPhones.value = showAllPhones.value ? getPhones.value : getPhones.value.slice(0, 2);
});

const toggleShowAll = () => {
    showAllPhones.value = !showAllPhones.value;
};
</script>

<style lang="scss">
.box-card-request-client-details {
    .client-overview-info-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    .client-overview-info-content {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 5px;
    }

    .client-overview-info-row {
        padding-bottom: 20px;

        &:not(:first-child) {
            border-top: 1px solid #CED4DA;
            padding-top: 20px;
        }
    }

    .client-details-info-row {
        &:not(:last-child) {
            border-bottom: 1px solid #E9ECEF;
            padding-bottom: 15px;
        }
    }

    .flex-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .client-name {
        flex-grow: 1;
    }

    .acms-v-collapse-toggle-status {
        margin-left: 10px;
    }
}

.table-mini {
    font-size: 14px;
}

.table-mini-cell:first-child {
    width: 106px;
    margin-right: 8px;
    flex-shrink: 0;
    font-weight: 600;
}

.table-mini-row {
    width: 100%;
}

.eye1-btn {
    padding: 0 !important;
    margin: 0 !important;
    border: 1.5px solid #bbb !important;
    position: relative;
    border-radius: 4px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.widget {
    width: 31px !important; /* Максимальный размер кнопки */
    height: 31px !important;
    border: 1.5px solid #fbc02d !important;
    border-radius: 4px !important;
    background-color: #ffeb3b!important;
    display: flex !important;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
    min-width: 31px !important;
    padding: 0 !important;
    margin: 0 5px 0 0!important;
    align-items: center !important;
    justify-content: center !important;

}

.last-search-icon {
    background-color: #ffeb3b; /* Желтая заливка */
    border-color: #fbc02d; /* Тёмно-желтая граница */
}

.last-search-icon i {
    transform: translateY(2px); /* Сдвигаем иконку вниз */
}
.highlight {
    background-color: #ffd77f;
}

</style>
